.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

// .row {
//   line-height: 24pt;
//   border: solid 1px black;
// }

// div.content-main>div:nth-of-type(odd) {
//   background: #e0e0e0;
// }


