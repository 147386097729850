.main {
  background: #fff;
  margin-top: 30px;
  border-radius: 6px;

  h3 {
    line-height: 22px;
    margin-bottom: 20px;
    font-size: 18px;
  }
}
.rs-panel-header {
  padding: 0 14px;
}
.rs-panel-body {
  padding: 10px;
}

.rs-panel-main {
  border-radius: 0;
  background-color: #f6f8fa;
}

.rs-panel-content {
  border-radius: 6px;
  background: linear-gradient(87deg, #ffffff 0, #ffffff 100%);
  border: 1px solid @B100;
}
