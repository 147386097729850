@lpad: 50px;
@spacer: 1rem;

.brand {
  padding: 10px 18px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  text-transform: uppercase;

  a {
    text-decoration: none;
  }
}

.laout-header {
  background-color: #fff;
  // box-shadow: 0 0 3px 0 rgb(156 156 156);
  box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 11%), 0 0.1875rem 0.4375rem rgb(0 0 0 / 13%);
  padding: 0;
  margin: 0;
  position: fixed;
  height: @lpad;
  width: 100%;
  z-index: 3;

  .rs-panel {
    // background: #fff;
    color: #fff;
  }
  .chart-img {
    width: 100px;
    position: absolute;
    left: 26px;
    top: 34px;
    opacity: 0.5;
  }
  .trend-box {
    .rs-panel-body {
      text-align: right;
      .value {
        font-size: 36px;
      }
    }
  }
}

.frame {
  height: 100vh;
  .rs-sidebar {
    top: @lpad;
    height: calc(100vh - @lpad);
    background: #fff;
    //box-shadow: 0px 9px 8px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 11%), 0 0.1875rem 0.4375rem rgb(0 0 0 / 13%);
  }
  .rs-sidenav {
    flex: 1 1 auto;
    transition: none !important;
    border-top: 1px solid @B100;
    width: 100%;
  }

  .rs-content {
    // padding: 0 10px;
    .rs-panel-header {
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .rs-breadcrumb {
        margin-bottom: 0;
      }
    }
  }
  .nav-toggle {
    border-top: 1px solid @B100;
  }

  .rs-sidenav-item,
  .rs-dropdown-item {
    &.active {
      color: @H500 !important;
    }
    &-icon {
      height: 1.3em !important;
      width: 1.3em !important;
    }
  }
}

.layout-page {
  min-width: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  border-radius: 0;
  background-color: #f6f8fa;
}

.page {
  min-width: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
}

.page-header {
  height: 38px;
  font-size: 20px;
  .title {
    font-size: 20px;
    color: #03768f;
  }
}

.page-content {
  overflow-y: auto;
  height: 100vh;
  border-radius: 6px;
  background: linear-gradient(87deg, #ffffff 0, #ffffff 100%);
  border: 1px solid @B100;
  padding: 10px;
  text-align: justify;
}

.px-2 {
  padding-left: (@spacer * 0.5) !important;
  padding-right: (@spacer * 0.5) !important;
}

.px-3 {
  padding-left: (@spacer) !important;
  padding-right: (@spacer) !important;
}

.p-3 {
  padding: @spacer !important;
}

.f-14 {
  font-size: 14px;
}
.icon-text {
  vertical-align: middle;
  // padding-left: 2px;
}
