#ac-page-list {
  //background-color: red;
}

#ac-page-grid {
  width: 100%;
  height: 100%;
  min-height: 600px;
  position: relative;
}
.ac-page-grid-loader {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #eceaea;
  text-align: center;
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.loader-center {
  position: relative;
  top: 40%;
}
