@import "~rsuite/styles/index.less";
@import "./icon.less";
@import "../components/layout/defaultLayout.less";
@import "../components/navbarItems/styles.less";
@import "../components/acComponents/loading/AcLoading.less";
@import "../components/acComponents/pageList/AcPageList.less";
@import "../pages/dashboard/styles.less";
@import "../pages/contact/styles.less";
// @import '../components/Logo/styles.less';
// @import '../components/ErrorPage/styles.less';
// @import '../components/Header/styles.less';
// @import '../components/RadioTile/styles.less';

// @import '../pages/tables/members/styles.less';
// @import '../pages/dashboard/styles.less';
// @import '../pages/forms/basic/styles.less';
// @import '../pages/forms/wizard/styles.less';
// @import '../pages/calendar/styles.less';

.Toastify__toast-container {
  z-index: 200000;
  }

//rewrite base color
@base-color: #34c3ff;

body {
  background: #f5f8fa;
}

.rdw-editor-wrapper {
  min-height: 300px;
  border: 1px solid #ccc;
}

.cell-expand-classname {
  /* needed on chrome */
  float: right;
  float: inline-end;
  display: table;
  block-size: 100%;
  > span {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
  }
}

.text-muted {
  color: @B700;
}

.rs-sidebar {
  position: fixed;
  height: 100vh;
  z-index: 3;
}

.page-container {
  top: 50px;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  width: calc(100vw);
  padding-left: 260px;
  transition: padding 0.5s;
  &.container-full {
    padding-left: 60px;
  }
}

// .page-container {
//   padding-left: 260px;
//   transition: padding 0.5s;
//   // position: fixed;
//   top: 50px;
//   bottom: 0;
//   &.container-full {
//     padding-left: 60px;
//   }
// }

.bg-gradient-orange {
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%);
}

.bg-gradient-red {
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%);
}

.bg-gradient-green {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
}

.bg-gradient-blue {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
}

.ql-container {
  min-height: 350px;
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.bd-example > .progress + .progress {
  margin-top: 1rem;
}
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@primary-color: #018a9e;